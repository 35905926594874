import useCallbackMain from './useCallbackMain';
import Loader from '../../Loader';

const CallbackMain = () => {
  useCallbackMain();
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Loader />
    </div>
  );
};

export default CallbackMain;
