import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';

const useAuthSuccessMain = () => {
  const navigate = useNavigate();
  const { activeAccount } = useKeylessAccounts();
  const hasLoggedOnce = useRef(false);
  const [hasLogged, setHasLogged] = useState(false);

  useEffect(() => {
    if (activeAccount === undefined) {
      navigate('/login');
    }
  }, [activeAccount, navigate]);

  const encryptKeysArray = useCallback((keysArray: any[]): string => {
    const ENCRYPTION_KEY = import.meta.env.VITE_ENCRYPTION_KEY;
    const IV = import.meta.env.VITE_IV;

    const encryptionKey = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV);

    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (_key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    const keysArrayString = JSON.stringify(keysArray, getCircularReplacer());

    const encrypted = CryptoJS.AES.encrypt(keysArrayString, encryptionKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encrypted;
  }, []);

  useEffect(() => {
    if (!hasLoggedOnce.current && !hasLogged && activeAccount && activeAccount.proof) {
      const accountBytes = activeAccount.bcsToBytes();
      const accountArray = Array.from(accountBytes);
      const keylessAccount = encryptKeysArray(accountArray);
      const keylessAccountExpire = activeAccount.ephemeralKeyPair.expiryDateSecs.toString();
      const oauthProvider = localStorage.getItem('oauth_provider');
      const idToken = localStorage.getItem('id_token');
      const accountAddress = activeAccount.accountAddress.toString();

      if (typeof (window as any).webkit !== 'undefined') {
        // iOS
        (window as any).webkit.messageHandlers.MilelogPublicApi_successLogin.postMessage({
          keylessAccount,
          keylessAccountExpire,
          oauthProvider,
          idToken,
          accountAddress,
        });
      } else {
        // Android
        (window as any).MilelogPublicApi.successLogin(
          keylessAccount,
          keylessAccountExpire,
          oauthProvider,
          idToken,
          accountAddress,
        );
      }
      hasLoggedOnce.current = true;
      setHasLogged(true);
    }
  }, [activeAccount, activeAccount?.proof, encryptKeysArray, hasLogged]);

  return {};
};

export default useAuthSuccessMain;
