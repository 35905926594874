import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage";
import CallbackPage from "./pages/CallbackPage";
import AuthSuccessPage from "./pages/AuthSuccessPage";
import TermsPrivacyPage from "./pages/TermsPrivacyPage";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Router = () => {
    return (
        <Routes>
            <Route path="/home" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/auth/apple/callback" element={<CallbackPage />} />
            <Route path="/auth-success" element={<AuthSuccessPage />} />
            <Route path="/terms-privacy" element={<TermsPrivacyPage />} />
            <Route path="/actuator/health" />
        </Routes>
    );
};
