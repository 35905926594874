import '../assets/styles/loader.css';
function Loader() {
    return (
        <span className="ouro ouro3">
            <span className="left"><span className="anim"></span></span>
            <span className="right"><span className="anim"></span></span>
        </span>
    );
}

export default Loader;
