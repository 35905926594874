import useAuthSuccessMain from './useAuthSuccessMain';
import Loader from '../../Loader';

const AuthSuccessMain = () => {
  useAuthSuccessMain();

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Loader />
    </div>
  );
};

export default AuthSuccessMain;
