import useLoginMain from './useLoginMain';

import AppleLogo from '~/components/icons/AppleLogo';
import CharanchaLogo from '~/components/icons/CharanchaLogo';
import GoogleLogo from '~/components/icons/GoogleLogo';
import MilelogLogo from '~/components/icons/MilelogLogo';

const LoginMain = () => {
  const { isIOS, handleLoginClick, loading } = useLoginMain();

  return (
    <div className="relative flex flex-col items-center justify-center px-4 text-center h-svh">
      <p className="mb-4">
        운전 습관으로 <br />
        차량 가치를 높이는 첫걸음
      </p>
      <h1 className="mb-14">
        <MilelogLogo />
      </h1>
      <div className="flex flex-col w-full gap-4">
        {isIOS && (
          <button
            onClick={() => handleLoginClick('APPLE')}
            className="relative flex justify-center items-center rounded-lg px-8 py-4 w-full text-black bg-[#F7F7FA]"
          >
            <div className="absolute left-8">
              <AppleLogo />
            </div>
            Apple로 시작하기
          </button>
        )}
        <button
          onClick={() => handleLoginClick('GOOGLE')}
          className={`relative flex justify-center items-center rounded-lg px-8 py-4 w-full text-black bg-[#F7F7FA] ${
            loading ? 'bg-[#E7E6EC]' : 'active:bg-[#E7E6EC]'
          }`}
        >
          <div className="absolute left-8">
            <GoogleLogo />
          </div>
          Google로 시작하기
        </button>
      </div>
      <div className="absolute bottom-[2.7rem]">
        <CharanchaLogo />
      </div>
    </div>
  );
};

export default LoginMain;
