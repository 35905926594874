import '../assets/styles/termsPrivacy.css';

function TermsPrivacyPage() {
    return (
        <>
            <div className="terms-wrap">
                <header>
                    <h1>마일로그 개인정보처리방침</h1>
                </header>

                <div style={{ marginTop: '4rem', fontSize: '1rem' }}>
                    차란차 주식회사 (이하 “회사”)는 마일로그 서비스(이하 "서비스”)를 제공함에 있어서 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보의 처리와 보호에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침(이하 ‘본 방침’)을 수립・공개합니다. <br /> <br />

                    <div style={{ margin: '0 0 2rem 2rem' }}>
                        1.처리하는 개인정보의 항목 <br />
                        2.개인정보의 이용목적 <br />
                        3.개인정보의 이용 및 제3자 제공 <br />
                        4.개인정보의 처리위탁 <br />
                        5.개인정보의 보유 및 이용기간 <br />
                        6.개인정보 파기절차 및 방법 <br />
                        7.만 14세 미만 아동의 개인정보 보호 <br />
                        8.이용자의 권리와 그 행사 방법 <br />
                        9.위치기반정보의 수집에 관한 사항<br />
                        10.개인정보의 기술적/관리적 보호 대책 <br />
                        11.개인정보 관리 책임자 및 담당자의 연락처 <br />
                        12.기타 <br />
                        13.고지의 의무 <br />
                        14.부칙 <br />
                    </div>


                    <h3>1. 처리하는 개인정보의 항목</h3>
                    <div>회사는 다음과 같은 개인정보 항목을 처리하고 있습니다.<br /><br />
                        1.회원가입 및 관리<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;a.필수 항목: 이메일, 이름, 단말기 제조사, 단말기 OS 버전, 단말기 모델<br /><br />
                        2. 차량등록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;a.필수 항목: 차량번호, 소유자명, 차대번호, 차량명, 형식연도, 연료<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;(이하 위 항목들을 총칭하여 ‘차량정보’라 한다)
                    </div>

                    <h3>2. 개인정보의 이용목적</h3>
                    <div>
                        회사는 다음의 목적을 위하여 개인정보를 처리합니다.<br /><br />
                        1.회원관리<br />
                        회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원의 부정이용 방지와 비인가사용 방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달<br /><br />
                        2.서비스 제공<br />
                        서비스 제공에 관한 계약 이행, 머신 러닝 모델 및 알고리즘 등의 당사 기술을 교육 및 개선, 맞춤형 콘텐츠 제공, 주행 데이터 집계<br /><br />
                        3.신규 서비스 개발 및 마케팅<br />
                        신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 이용자의 서비스 이용에 대한 통계
                    </div>
                    <h3>3. 개인정보의 이용 및 제3자 제공</h3>
                    <div>
                        회사는 이용자의 개인정보를 "2. 개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br /><br />

                        - 이용자가 사전에 공개에 동의하였거나 서비스 제공 관련 계약 이행을 위하여 필요한 개인정보로 통상의 동의를 받기가 경제적/기술적 사유로 현저히 곤란한 경우<br /><br />

                        - 기타 법에 의해 요구된다고 선의로 판단되는 경우 (예. 관련 법령에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)<br /><br />

                        - 서비스 이용에 필요한 목적을 위해 이용자의 개인정보에 대해서 제3자에게 제공(별도 이용자의 동의 여부 확인)<br /><br />

                        사업의 전부 또는 일부 양도, 인수합병 등으로 서비스 제공자의 권리와 의무를 이전 또는 승계하는 경우에는 그 사실을 사전에 상세하게 고지하고, 개인정보 수집/이용 등에 대한 동의 철회의 선택권을 부여합니다.<br /><br />

                        이용자가 온라인상의 게시판 등을 통해 기재한 인적사항을 제3자가 수집하는 경우가 있을 수 있으므로 이에 유의하시기 바랍니다. 이용자가 스스로 게시판 등을 통해 기재한 인적사항과 관련하여 회사는 어떠한 책임도 지지 않습니다.<br /><br />

                        제3자에게 제공하는 개인정보 내역은 다음과 같습니다.<br /><br />

                        &lt;서비스 개인정보 제공 내역&gt;<br />
                        1.서비스명: 차량등록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;a.제공받는 자: 기웅정보통신<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;b.개인정보 항목: 차량번호, 소유자명, 차대번호, 차량명, 형식연도, 연료 등 차량정보<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;c.이용목적: 차량번호와 소유자명 일치 여부 확인 및 관련 차량정보 확인<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;d.이용기간: 이용자의 회원 탈퇴 또는 동의 철회시까지(관련 법령에 따라 보존할 필요가 있는 경우는 해당 보존기간)
                    </div>
                    <h3>4. 개인정보의 처리위탁</h3>
                    <div>
                        1. 회사는 서비스 향상을 위해서 필요한 경우 및 기타 서비스 제공을 위해서 이용자의 개인정보를 외부에 수집·보관·처리·이용·제공·관리·파기 등을 할 수 있도록 아래와 같이 업무를 위탁하여 운영하고 있습니다. 회사는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고 시의 책임부담 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관하여 이용자의 권익을 보호하고 있습니다.<br /><br />

                        가. 수탁업체: Amazon Web Services (AWS Korea)<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;위탁업무 내용: 클라우드 IT 인프라 제공<br />
                        나. 수탁업체: Aptos foundation<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;위탁업무 내용: 앱토스 네트워크 account<br /><br />
                        2. 회사는 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.<br /><br />
                        3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 공지사항을 통하여 공개하고 있습니다.

                    </div>
                    <h3>5. 개인정보의 처리 및 보유기간</h3>
                    <div>
                        회사는 이용자가 회원으로서 서비스를 이용하는 동안 개인정보를 처리 및 보유하며, 이용자가 회원탈퇴를 요청한 경우나 개인정보의 수집 및 이용목적을 달성하거나 보유 및 이용기간이 종료한 경우 또는 사업폐지 등의 사유가 발생한 경우 해당 정보를 지체 없이 파기합니다.<br /><br />

                        회사는 이용자가 회원탈퇴를 요청한 경우 개인정보 도용 등으로 인한 원치 않는 회원탈퇴, 회원의 부정이용행위 등을 방지하기 위하여 회원탈퇴 신청 후 7일 간 개인정보를 보존합니다. 또한 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br /><br />

                        가. 회사 내부 방침에 의한 정보 보유 사유<br />
                        부정 이용 기록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 이유 : 부정 이용 방지<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 기간 : 1년<br /><br />

                        나. 관련 법령에 의한 정보 보유 사유<br />
                        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br /><br />

                        계약 또는 청약철회 등에 관한 기록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 기간 : 5년<br /><br />
                        대금결제 및 재화 등의 공급에 관한 기록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 기간 : 5년<br /><br />
                        소비자의 불만 또는 분쟁처리에 관한 기록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 기간 : 3년<br /><br />
                        로그인 기록<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 이유 : 통신비밀보호법<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;ㄴ보존 기간 : 3개월<br />
                    </div>
                    <h3>6. 개인정보 파기절차 및 방법</h3>
                    <div>
                        회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 수집한 이용자의 개인정보의 수집 및 이용목적이 달성되거나, 이용자가 회원탈퇴를 요청한 경우 지체 없이 파기하여 향후 어떠한 용도로도 열람 또는 이용할 수 없도록 처리합니다.<br /><br /> 단, “5. 개인정보의 보유 및 이용기간 가, 나”와 같은 예외 경우를 두고 있습니다. 회사의 개인정보 파기절치 및 방법은 다음과 같습니다.<br /><br />

                        가. 파기절차<br />
                        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(5. 개인정보의 처리 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br /><br />

                        나. 파기방법<br />
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                    </div>
                    <h3>7. 만 14세 미만 아동의 개인정보 보호</h3>
                    <div>
                        회사가 운영하는 서비스에는 만14세 미만 아동의 경우 회원 가입이 불가능합니다.
                    </div>

                    <h3>8. 이용자의 권리와 그 행사 방법</h3>
                    <div>
                        이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지(동의철회)를 요청할 수도 있습니다. 이용자의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 ’회원탈퇴’를 클릭하여 본인 확인 절 차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br /><br /> 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br /><br />

                        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도 로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                    </div>
                    <h3>9. 위치기반정보의 수집에 관한 사항</h3>
                    <div>
                        차란차의 위치정보 수집 이용 목적은 다음과 같습니다. <br />
                        - 이용목적: 서비스 제공 <br />
                        - 수집항목: 주행 거리, 주행 속력, 고도, 가속력, 위도, 경도 (단, 위도 및 경도에 관하여 수집된 정보는 유저 단말기에만 저장될 뿐 서버로 전송되지 아니하고, 기타 방법으로 처리되지 아니합니다.) <br />
                        이용자는 위치정보 수집 및 이용 동의에 거부할 권리가 있으며, 동의 거부 시 회원가입 및 서비스 이용이 불가합니다.
                    </div>
                    <h3>10. 개인정보의 기술적/관리적 보호 대책</h3>
                    <div>
                        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br /><br />

                        가. 개인정보 암호화<br />
                        이용자의 주요 개인정보는 암호화하여 저장하고 있으며, 파일 및 전송데이터를 암호화하여 혹시 발생할 수 있는 사고 시라도 이용자의 개인정보가 유출되지 않도록 관리되고 있습니다.<br /><br />

                        나. 해킹 등에 대비한 대책<br />
                        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br /> 그리고 24시간 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br /><br />

                        다. 취급 직원의 최소화 및 교육<br />
                        회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다. 또한, 임직원이 이용자의 개인정보를 취급하기 이전에 보안서약서를 통하여 이용자의 개인정보에 대한 정보유출을 사전에 방지하고 관련 사태규정을 마련하여 이에 대한 이행사항 및 준수 여부를 감시하기 위한 내부절차를 마련하고 있습니다.
                    </div>
                    <h3>11. 개인정보 관리 책임자 및 담당자의 연락처</h3>
                    <div>
                        회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다.<br /><br />

                        개인정보 보호책임자<br />
                        대표이사 박창우<br />
                        privacy@charancha.com<br /><br />

                        개인정보 보호담당자<br />
                        인프라보안 김정훈 차장<br />
                        privacy@charancha.com<br /><br />

                        대표번호 : 02-466-7923<br /><br />

                        귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br /><br />

                        개인정보 보호 종합지원 포털(안전행정부 운영)<br />
                        www.privacy.go.kr / 02-2100-3343<br /><br />
                        개인정보 침해신고센터 (한국인터넷진흥원 운영)<br />
                        privacy.kisa.or.kr / (국번없이) 118<br /><br />
                        개인정보보호지원센터 (한국정보화진흥원)<br />
                        nia.or.kr / 02-2131-0111<br /><br />
                        개인정보분쟁조정위원회<br />
                        www.kopico.go.kr / 1833-6972<br /><br />
                        대검찰청 사이버수사과<br />
                        www.spo.go.kr / (국번없이) 1301<br /><br />
                        경찰청 사이버안전국<br />
                        cyberbureau.police.go.kr / 02-3150-2659<br /><br />
                    </div>
                    <h3>12. 기타</h3>
                    <div>
                        회사가 제공하는 서비스에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 방침이 적용되지 않음을 알려 드립니다.
                    </div>
                    <h3>13. 고지의 의무</h3>
                    <div>
                        본 방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 변경이유 및 내용을 홈페이지의 '공지사항'을 통해 고지할 것입니다.<br /><br />
                        본 방침의 내용은 수시로 변경될 수 있으므로 사이트를 방문하실 때마다, 이를 확인하시기 바랍니다.
                    </div>
                    <h3>14. 부칙</h3>
                    <div>
                        본 방침은 2024년 8월 13일부터 적용됩니다.
                    </div>
                </div>
            </div>
        </>
    )
}
export default TermsPrivacyPage;
