import { useState, useEffect, useMemo } from 'react';

import { createEphemeralKeyPair } from '~/core/ephemeral';
import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';

const useLoginMain = () => {
  const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const GOOGLE_AUTH_URL = import.meta.env.VITE_GOOGLE_AUTH_URL;
  const APPLE_CLIENT_ID = import.meta.env.VITE_APPLE_CLIENT_ID;
  const APPLE_AUTH_URL = import.meta.env.VITE_APPLE_AUTH_URL;
  const originUrl = location.origin;

  const [loading, setLoading] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const { activeAccount, disconnectKeylessAccount, commitEphemeralKeyPair, getEphemeralKeyPair } = useKeylessAccounts();

  const ephemeralKeyPair = useMemo(() => {
    let keyPair = getEphemeralKeyPair();

    // If no key pair is found, create a new one and commit it to the store
    if (!keyPair) {
      keyPair = createEphemeralKeyPair({
        expiryDateSecs: Number(BigInt(Math.floor(Date.now() / 1000)) + BigInt(60 * 60 * 24 * 30)),
      });
      // keyPair = createEphemeralKeyPair();
      commitEphemeralKeyPair(keyPair);
    }

    return keyPair;
  }, [commitEphemeralKeyPair, getEphemeralKeyPair]);

  useEffect(() => {
    if (activeAccount) {
      disconnectKeylessAccount();
    }
  }, [activeAccount, disconnectKeylessAccount]);

  const buildGoogleAuthUrl = () => {
    const redirectUrl = new URL(GOOGLE_AUTH_URL);
    const searchParams = new URLSearchParams({
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${originUrl}/callback`,
      response_type: 'id_token token',
      scope: 'openid email profile',
      nonce: ephemeralKeyPair.nonce,
      prompt: 'select_account',
    });
    redirectUrl.search = searchParams.toString();
    return redirectUrl.toString();
  };

  const buildAppleAuthUrl = () => {
    const redirectUrl = new URL(APPLE_AUTH_URL);
    const searchParams = new URLSearchParams({
      client_id: APPLE_CLIENT_ID,
      redirect_uri: `${originUrl}/auth/apple/callback`,
      response_type: 'code id_token',
      scope: 'openid name email',
      response_mode: 'form_post',
      nonce: ephemeralKeyPair.nonce,
    });
    redirectUrl.search = searchParams.toString();
    return redirectUrl.toString();
  };

  const handleLoginClick = (provider: string) => {
    setLoading(true);
    if (provider === 'GOOGLE') {
      localStorage.setItem('oauth_provider', 'GOOGLE');
      window.location.href = buildGoogleAuthUrl();
    } else if (provider === 'APPLE') {
      localStorage.setItem('oauth_provider', 'APPLE');
      window.location.href = buildAppleAuthUrl();
    }
  };

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (isIOS) {
      setIsIOS(true);
    }
  }, []);

  return { isIOS, handleLoginClick, loading };
};

export default useLoginMain;
