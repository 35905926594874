import { EphemeralKeyPair, KeylessAccount, ProofFetchStatus, Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk';
import { jwtDecode } from 'jwt-decode';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { EphemeralKeyPairEncoding, isValidEphemeralKeyPair, validateEphemeralKeyPair } from '../core/ephemeral';

import { EncryptedScopedIdToken, scopedPayloadSchema } from '~/types/IdTokenTypes';
import { KeylessAccountsActions, KeylessAccountsState } from '~/types/keylessAccountTypes';

const LocalStorageKeys = {
  keylessAccounts: '@aptos-connect/keyless-accounts',
};

const networkMap: { [key: string]: Network } = {
  MAINNET: Network.MAINNET,
  TESTNET: Network.TESTNET,
  DEVNET: Network.DEVNET,
};

const network = networkMap[import.meta.env.VITE_APTOS_NETWORK as string] || Network.DEVNET;

const devnetClient = new Aptos(new AptosConfig({ network }));

const decodeIdToken = (jwt: string): EncryptedScopedIdToken => scopedPayloadSchema.parse(jwtDecode(jwt));

const isValidIdToken = (jwt: string | EncryptedScopedIdToken): boolean => {
  if (typeof jwt === 'string') return isValidIdToken(decodeIdToken(jwt));

  // Check whether the token has an expiration, nonce, and is not expired
  if (!jwt.nonce) return false;

  return true;
};

const validateIdToken = (jwt: string | EncryptedScopedIdToken): EncryptedScopedIdToken | null => {
  if (typeof jwt === 'string') return validateIdToken(decodeIdToken(jwt));
  return isValidIdToken(jwt) ? jwt : null;
};

const KeylessAccountEncoding = {
  decode: (e: any) => KeylessAccount.fromBytes(e.data),
  // If the account has a proof, it can be persisted, otherwise,
  // it should not be stored.
  encode: (e: KeylessAccount) =>
    e.proof
      ? {
          __type: 'KeylessAccount',
          data: e.bcsToBytes(),
        }
      : undefined,
};

const validateKeylessAccount = (account: KeylessAccount): KeylessAccount | undefined =>
  // Check the Ephemeral key pair expiration
  isValidEphemeralKeyPair(account.ephemeralKeyPair) &&
  // Check the idToken for nonce
  isValidIdToken(account.jwt) &&
  // If the EphemeralAccount nonce algorithm changes, this will need to be updated
  decodeIdToken(account.jwt).nonce === account.ephemeralKeyPair.nonce
    ? account
    : undefined;

const storage = createJSONStorage<KeylessAccountsState>(() => localStorage, {
  replacer: (_, e) => {
    if (typeof e === 'bigint') return { __type: 'bigint', value: e.toString() };
    if (e instanceof Uint8Array) return { __type: 'Uint8Array', value: Array.from(e) };
    if (e instanceof EphemeralKeyPair) return EphemeralKeyPairEncoding.encode(e);
    if (e instanceof KeylessAccount) return KeylessAccountEncoding.encode(e);
    return e;
  },
  reviver: (_, e: any) => {
    if (e && e.__type === 'bigint') return BigInt(e.value);
    if (e && e.__type === 'Uint8Array') return new Uint8Array(e.value);
    if (e && e.__type === 'EphemeralKeyPair') return EphemeralKeyPairEncoding.decode(e);
    if (e && e.__type === 'KeylessAccount') return KeylessAccountEncoding.decode(e);
    return e;
  },
});

export const useKeylessAccounts = create<KeylessAccountsState & KeylessAccountsActions>()(
  persist(
    (set, get, store) => ({
      accounts: [],
      commitEphemeralKeyPair: (keyPair) => {
        const valid = isValidEphemeralKeyPair(keyPair);
        if (!valid) throw new Error('addEphemeralKeyPair: Invalid ephemeral key pair provided');
        set({ ephemeralKeyPair: keyPair });
      },

      disconnectKeylessAccount: () => {
        set({ activeAccount: undefined });
        const oauthProvider = localStorage.getItem('oauth_provider');
        if (oauthProvider === 'GOOGLE') {
          localStorage.removeItem('access_token');
        } else if (oauthProvider === 'APPLE') {
          localStorage.removeItem('authorization_code');
        }
        localStorage.removeItem('oauth_provider');
        localStorage.removeItem('id_token');
      },

      getEphemeralKeyPair: () => {
        const account = get().ephemeralKeyPair;
        return account ? validateEphemeralKeyPair(account) : undefined;
      },

      switchKeylessAccount: async (idToken: string) => {
        set({ activeAccount: undefined });

        const decodedToken = validateIdToken(idToken);
        if (!decodedToken) {
          throw new Error('switchKeylessAccount: Invalid idToken provided, could not decode');
        }

        const ephemeralKeyPair = get().getEphemeralKeyPair();
        if (!ephemeralKeyPair || ephemeralKeyPair?.nonce !== decodedToken.nonce) {
          throw new Error('switchKeylessAccount: Ephemeral key pair not found or nonce mismatch');
        }

        const proofFetchCallback = async (res: ProofFetchStatus) => {
          if (res.status === 'Failed') {
            get().disconnectKeylessAccount();
          } else {
            store.persist.rehydrate();
          }
        };

        const storedAccount = get().accounts.find((a) => a.idToken.decoded.sub === decodedToken.sub);

        let activeAccount: KeylessAccount | undefined;
        try {
          activeAccount = await devnetClient.deriveKeylessAccount({
            ephemeralKeyPair,
            jwt: idToken,
            proofFetchCallback,
          });
        } catch (error) {
          // TODO: 429 error 처리
          if (!storedAccount?.pepper) throw error;
          activeAccount = await devnetClient.deriveKeylessAccount({
            ephemeralKeyPair,
            jwt: idToken,
            pepper: storedAccount.pepper,
            proofFetchCallback,
          });
        }

        const { pepper } = activeAccount;

        // Wrap state update in a Promise
        await new Promise<void>((resolve) => {
          set({
            accounts: storedAccount
              ? get().accounts.map((a) =>
                  a.idToken.decoded.sub === decodedToken.sub
                    ? {
                        idToken: { decoded: decodedToken, raw: idToken },
                        pepper,
                      }
                    : a,
                )
              : [...get().accounts, { idToken: { decoded: decodedToken, raw: idToken }, pepper }],
            activeAccount,
          });
          resolve();
        });

        return activeAccount;
      },
    }),
    {
      merge: (persistedState, currentState) => {
        const merged = { ...currentState, ...(persistedState as object) };
        return {
          ...merged,
          activeAccount: merged.activeAccount && validateKeylessAccount(merged.activeAccount),
          ephemeralKeyPair: merged.ephemeralKeyPair && validateEphemeralKeyPair(merged.ephemeralKeyPair),
        };
      },
      name: LocalStorageKeys.keylessAccounts,
      partialize: ({ activeAccount, ephemeralKeyPair, ...state }) => ({
        ...state,
        activeAccount: activeAccount && validateKeylessAccount(activeAccount),
        ephemeralKeyPair: ephemeralKeyPair && validateEphemeralKeyPair(ephemeralKeyPair),
      }),
      storage,
      version: 1,
    },
  ),
);
