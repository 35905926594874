import CharanchaLogo from '~/components/icons/CharanchaLogo';
import MilelogLogo from '~/components/icons/MilelogLogo';

function MainPage() {
  return (
    <div className="relative flex flex-col items-center justify-center px-4 text-center h-svh">
      <p className="mb-4">
        운전 습관으로 <br />
        차량 가치를 높이는 첫걸음
      </p>
      <h1 className="mb-14">
        <MilelogLogo />
      </h1>
      <div className="absolute bottom-[2.7rem]">
        <CharanchaLogo />
      </div>
    </div>
  );
}

export default MainPage;
