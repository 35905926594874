const CharanchaLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="24" viewBox="0 0 78 24" fill="none">
      <path
        d="M53.9304 14.6975V13.1875C53.9304 13.0825 53.8887 12.9818 53.8145 12.9076C53.7402 12.8333 53.6395 12.7916 53.5345 12.7916H47.0577V10.8803H52.6771C52.7288 10.8803 52.7799 10.8701 52.8276 10.8503C52.8753 10.8306 52.9186 10.8016 52.9551 10.7651C52.9917 10.7286 53.0206 10.6852 53.0404 10.6375C53.0601 10.5898 53.0703 10.5387 53.0703 10.4871V4.66838C53.0707 4.61652 53.0608 4.5651 53.0412 4.51708C53.0216 4.46906 52.9927 4.42539 52.9561 4.38859C52.9196 4.35179 52.8761 4.32258 52.8282 4.30265C52.7803 4.28272 52.729 4.27246 52.6771 4.27246H45.3048C45.1998 4.27246 45.0991 4.31417 45.0248 4.38842C44.9506 4.46267 44.9088 4.56338 44.9088 4.66838V6.18108C44.9096 6.28561 44.9516 6.38561 45.0258 6.45927C45.0999 6.53293 45.2003 6.57427 45.3048 6.57427H50.7658V8.57026H45.1437C45.0394 8.57026 44.9394 8.61169 44.8656 8.68543C44.7919 8.75917 44.7505 8.85918 44.7505 8.96346V14.6975C44.7505 14.802 44.7918 14.9023 44.8655 14.9765C44.9391 15.0507 45.0391 15.0927 45.1437 15.0934H53.529C53.5815 15.0941 53.6336 15.0844 53.6822 15.0649C53.7309 15.0453 53.7752 15.0162 53.8125 14.9794C53.8499 14.9426 53.8795 14.8987 53.8998 14.8503C53.92 14.8019 53.9304 14.75 53.9304 14.6975Z"
        fill="#B0B0B3"
      />
      <path
        d="M59.8118 9.23377H57.5428V4.67931C57.5428 4.62588 57.5323 4.57297 57.5118 4.52361C57.4914 4.47425 57.4614 4.4294 57.4236 4.39162C57.3859 4.35385 57.341 4.32388 57.2916 4.30343C57.2423 4.28298 57.1894 4.27246 57.1359 4.27246H55.6478C55.5944 4.27246 55.5415 4.28298 55.4921 4.30343C55.4428 4.32388 55.3979 4.35385 55.3601 4.39162C55.3223 4.4294 55.2924 4.47425 55.272 4.52361C55.2515 4.57297 55.241 4.62588 55.241 4.67931V15.6969C55.241 15.8048 55.2838 15.9082 55.3601 15.9845C55.4364 16.0608 55.5399 16.1037 55.6478 16.1037H57.1359C57.2439 16.1037 57.3473 16.0608 57.4236 15.9845C57.4999 15.9082 57.5428 15.8048 57.5428 15.6969V11.5329H59.8118C59.919 11.5329 60.0218 11.4903 60.0976 11.4145C60.1734 11.3387 60.216 11.2359 60.216 11.1287V9.63789C60.216 9.53071 60.1734 9.42792 60.0976 9.35214C60.0218 9.27635 59.919 9.23377 59.8118 9.23377Z"
        fill="#B0B0B3"
      />
      <path
        d="M57.1383 18.8946H49.5257V17.0352C49.5257 16.9287 49.4834 16.8266 49.4081 16.7514C49.3329 16.6761 49.2308 16.6338 49.1243 16.6338H47.6253C47.5188 16.6338 47.4167 16.6761 47.3414 16.7514C47.2662 16.8266 47.2239 16.9287 47.2239 17.0352V20.7951C47.2239 20.9015 47.2662 21.0036 47.3414 21.0789C47.4167 21.1542 47.5188 21.1964 47.6253 21.1964H57.1383C57.2448 21.1964 57.3469 21.1542 57.4221 21.0789C57.4974 21.0036 57.5397 20.9015 57.5397 20.7951V19.296C57.5397 19.1896 57.4974 19.0875 57.4221 19.0122C57.3469 18.9369 57.2448 18.8946 57.1383 18.8946Z"
        fill="#B0B0B3"
      />
      <path
        d="M76.8392 9.23377H74.5674V4.67384C74.5674 4.56739 74.5251 4.4653 74.4499 4.39002C74.3746 4.31475 74.2725 4.27246 74.166 4.27246H72.667C72.5605 4.27246 72.4585 4.31475 72.3832 4.39002C72.3079 4.4653 72.2656 4.56739 72.2656 4.67384V16.232C72.2656 16.3385 72.3079 16.4406 72.3832 16.5159C72.4585 16.5911 72.5605 16.6334 72.667 16.6334H74.166C74.2725 16.6334 74.3746 16.5911 74.4499 16.5159C74.5251 16.4406 74.5674 16.3385 74.5674 16.232V11.5329H76.8392C76.9452 11.5329 77.0469 11.4909 77.1221 11.4163C77.1973 11.3416 77.2399 11.2402 77.2406 11.1342V9.63516C77.2406 9.5287 77.1983 9.42661 77.123 9.35134C77.0477 9.27606 76.9457 9.23377 76.8392 9.23377Z"
        fill="#B0B0B3"
      />
      <path
        d="M67.5256 11.4755C69.355 9.46861 70.3107 6.99751 70.6383 6.01453C70.6587 5.95552 70.6646 5.8925 70.6556 5.83074C70.6466 5.76898 70.6229 5.71028 70.5865 5.65956C70.55 5.60831 70.5016 5.56665 70.4455 5.53812C70.3894 5.5096 70.3272 5.49505 70.2643 5.49573H66.4662V3.09563C66.4662 2.99062 66.4244 2.88992 66.3502 2.81567C66.2759 2.74142 66.1752 2.69971 66.0702 2.69971H64.5575C64.453 2.70043 64.353 2.74246 64.2793 2.81663C64.2057 2.8908 64.1643 2.99109 64.1643 3.09563V5.49573H60.9697C60.8647 5.49573 60.7639 5.53745 60.6897 5.6117C60.6154 5.68595 60.5737 5.78665 60.5737 5.89166V7.40162C60.5737 7.50663 60.6154 7.60733 60.6897 7.68158C60.7639 7.75583 60.8647 7.79754 60.9697 7.79754H67.2498C65.6168 10.6008 63.2711 12.922 60.4509 14.5255C60.3894 14.5597 60.3381 14.6095 60.3022 14.6699C60.2662 14.7304 60.2469 14.7992 60.2461 14.8695V16.5734C60.2463 16.6407 60.2637 16.707 60.2967 16.7657C60.3297 16.8245 60.3771 16.8739 60.4345 16.9092C60.497 16.9476 60.5687 16.9683 60.642 16.9693C60.7017 16.9691 60.7606 16.955 60.814 16.9283C62.7394 15.9775 64.4928 14.7123 66.002 13.1848L69.3086 16.3877C69.3834 16.4563 69.4802 16.496 69.5816 16.4996C69.6846 16.4956 69.7822 16.4527 69.8547 16.3795L70.9059 15.2873C70.9778 15.2146 71.018 15.1165 71.0179 15.0142C71.0153 14.911 70.9721 14.8129 70.8978 14.7412L67.5256 11.4755Z"
        fill="#B0B0B3"
      />
      <path
        d="M43.1964 9.23377H40.9246V4.67384C40.9246 4.62113 40.9142 4.56894 40.8941 4.52024C40.8739 4.47154 40.8443 4.4273 40.8071 4.39002C40.7698 4.35275 40.7255 4.32318 40.6768 4.30301C40.6281 4.28284 40.576 4.27246 40.5233 4.27246H39.0242C38.9178 4.27246 38.8156 4.31475 38.7404 4.39002C38.6651 4.4653 38.6228 4.56739 38.6228 4.67384V16.232C38.6228 16.3385 38.6651 16.4406 38.7404 16.5159C38.8156 16.5911 38.9178 16.6334 39.0242 16.6334H40.5233C40.576 16.6334 40.6281 16.623 40.6768 16.6029C40.7255 16.5827 40.7698 16.5531 40.8071 16.5159C40.8443 16.4786 40.8739 16.4343 40.8941 16.3856C40.9142 16.3369 40.9246 16.2847 40.9246 16.232V11.5329H43.1964C43.3024 11.5329 43.4041 11.4909 43.4793 11.4163C43.5545 11.3416 43.5971 11.2402 43.5978 11.1342V9.63516C43.5978 9.5287 43.5555 9.42661 43.4802 9.35134C43.4049 9.27606 43.3028 9.23377 43.1964 9.23377Z"
        fill="#B0B0B3"
      />
      <path
        d="M37.2553 14.7442L33.8831 11.4676C35.7126 9.46067 36.671 6.98957 36.9986 6.0066C37.0186 5.94738 37.0241 5.88423 37.0146 5.82246C37.0051 5.76069 36.9809 5.70211 36.944 5.65163C36.9079 5.60059 36.86 5.55903 36.8044 5.53051C36.7488 5.50198 36.6871 5.48733 36.6246 5.4878H32.8237V3.08769C32.8237 2.98316 32.7824 2.88287 32.7087 2.80869C32.6351 2.73452 32.5351 2.69249 32.4305 2.69177H30.9178C30.8128 2.69177 30.7121 2.73349 30.6379 2.80774C30.5636 2.88199 30.5219 2.98269 30.5219 3.08769V5.4878H27.3272C27.2737 5.48665 27.2206 5.49643 27.171 5.51654C27.1214 5.53665 27.0765 5.56667 27.0389 5.60476C27.0013 5.64284 26.9719 5.6882 26.9525 5.73804C26.933 5.78789 26.924 5.84118 26.9258 5.89464V7.40461C26.9255 7.45647 26.9354 7.5079 26.955 7.55592C26.9746 7.60394 27.0035 7.6476 27.04 7.6844C27.0766 7.7212 27.12 7.75041 27.1679 7.77034C27.2158 7.79027 27.2671 7.80053 27.319 7.80053H33.5992C31.9662 10.6038 29.6205 12.925 26.8002 14.5285C26.7388 14.5627 26.6875 14.6125 26.6515 14.6729C26.6156 14.7333 26.5962 14.8022 26.5955 14.8725V16.5763C26.5957 16.6437 26.6131 16.7099 26.6461 16.7687C26.679 16.8275 26.7265 16.8769 26.7838 16.9122C26.8463 16.9506 26.918 16.9713 26.9914 16.9723C27.0511 16.9724 27.1101 16.9583 27.1634 16.9313C29.0888 15.9805 30.8421 14.7153 32.3513 13.1878L35.658 16.3907C35.7313 16.4614 35.8291 16.5009 35.931 16.5009C36.0329 16.5009 36.1308 16.4614 36.2041 16.3907L37.258 15.2985C37.3281 15.2249 37.3673 15.1271 37.3673 15.0254C37.3692 14.9733 37.3603 14.9214 37.341 14.873C37.3217 14.8245 37.2925 14.7807 37.2553 14.7442Z"
        fill="#B0B0B3"
      />
      <path
        d="M18.1882 1.46859L17.3691 0.649442C17.3117 0.59157 17.2434 0.545634 17.1682 0.514286C17.093 0.482937 17.0123 0.466797 16.9308 0.466797C16.8493 0.466797 16.7687 0.482937 16.6934 0.514286C16.6182 0.545634 16.55 0.59157 16.4926 0.649442L11.7661 5.34863L9.44789 3.03043C9.39057 2.97304 9.32245 2.92757 9.24747 2.89664C9.17249 2.86571 9.09213 2.84993 9.01102 2.85022C8.92945 2.84957 8.84858 2.86517 8.7731 2.89611C8.69762 2.92705 8.62905 2.97271 8.57141 3.03043L7.75226 3.84958C7.69487 3.9069 7.6494 3.97502 7.61847 4.05C7.58754 4.12499 7.57175 4.20535 7.57204 4.28646C7.57139 4.36803 7.587 4.44892 7.61794 4.52439C7.64888 4.59987 7.69454 4.66843 7.75226 4.72607L11.3401 8.31394C11.3979 8.37154 11.4664 8.41712 11.5419 8.44805C11.6173 8.47898 11.6982 8.49466 11.7797 8.49416C11.8608 8.4943 11.9411 8.47845 12.0161 8.44753C12.0911 8.41661 12.1592 8.37121 12.2166 8.31394L18.1882 2.34508C18.2463 2.28757 18.2924 2.2191 18.3238 2.14363C18.3552 2.06816 18.3713 1.98721 18.3712 1.90547C18.3709 1.82419 18.3547 1.74375 18.3233 1.66878C18.2919 1.59381 18.246 1.52577 18.1882 1.46859Z"
        fill="#B0B0B3"
      />
      <path
        d="M8.9592 10.0506L5.12014 2.52265C5.08403 2.44953 5.03347 2.3845 4.97152 2.33147C4.90957 2.27844 4.83751 2.23851 4.7597 2.2141C4.6825 2.18861 4.60104 2.17863 4.51998 2.18472C4.43891 2.19081 4.35985 2.21286 4.28733 2.2496L3.24156 2.7957C3.16872 2.83219 3.10389 2.88288 3.0509 2.94477C2.99792 3.00667 2.95784 3.07852 2.93301 3.15612C2.88268 3.31243 2.89643 3.48232 2.97124 3.6285L5.99389 9.55914L3.07227 11.05C2.99943 11.0865 2.9346 11.1372 2.88161 11.1991C2.82863 11.261 2.78855 11.3328 2.76372 11.4104C2.71339 11.5667 2.72714 11.7366 2.80195 11.8828L3.33439 12.9286C3.37126 13.0011 3.42207 13.0658 3.48391 13.1187C3.54575 13.1716 3.61744 13.2119 3.69483 13.2371C3.75673 13.2564 3.82112 13.2665 3.88595 13.2672C3.98096 13.2653 4.07432 13.242 4.159 13.1989L8.68072 10.8944C8.75356 10.8579 8.81836 10.8072 8.87134 10.7453C8.92433 10.6834 8.96444 10.6115 8.98927 10.5339C9.01695 10.4555 9.02856 10.3723 9.0234 10.2892C9.01824 10.2062 8.99639 10.125 8.9592 10.0506Z"
        fill="#B0B0B3"
      />
      <path
        d="M11.1023 19.2027L10.3077 14.1923C10.2961 14.1115 10.268 14.034 10.2253 13.9645C10.1825 13.895 10.126 13.835 10.0592 13.7882C9.9943 13.7395 9.92019 13.7045 9.84135 13.6852C9.76251 13.666 9.68059 13.6629 9.60053 13.6762L1.25065 15.0005C1.17028 15.0134 1.09324 15.0419 1.0239 15.0845C0.954561 15.1272 0.894289 15.183 0.846544 15.249C0.798809 15.3149 0.764595 15.3896 0.74585 15.4688C0.727105 15.548 0.724194 15.6301 0.737317 15.7104L0.920272 16.8682C0.946517 17.0304 1.03584 17.1756 1.16876 17.2723C1.23447 17.3205 1.30907 17.3552 1.38828 17.3744C1.46748 17.3936 1.5497 17.397 1.63019 17.3842L8.20522 16.3412L8.71855 19.5795C8.73126 19.66 8.75977 19.7371 8.80241 19.8064C8.84506 19.8758 8.90101 19.936 8.96704 19.9837C9.07186 20.062 9.19934 20.1042 9.33022 20.1038C9.36293 20.1065 9.3958 20.1065 9.42851 20.1038L10.5862 19.9208C10.6674 19.9079 10.7452 19.879 10.8152 19.8359C10.8852 19.7927 10.9459 19.7361 10.9939 19.6693C11.0419 19.6026 11.0762 19.527 11.0948 19.4469C11.1134 19.3668 11.1159 19.2838 11.1023 19.2027Z"
        fill="#B0B0B3"
      />
      <path
        d="M19.436 14.8317C19.3941 14.7368 19.3293 14.6539 19.2473 14.5905C19.1652 14.5271 19.0687 14.4852 18.9663 14.4685L13.9559 13.674C13.7935 13.6495 13.628 13.6899 13.4951 13.7865C13.3623 13.8831 13.2728 14.028 13.246 14.19L11.9271 22.5371C11.9062 22.6595 11.9234 22.7853 11.9763 22.8976C12.0176 22.9931 12.0822 23.0767 12.1643 23.1406C12.2464 23.2045 12.3432 23.2468 12.4459 23.2635L13.6064 23.4464C13.6391 23.4491 13.672 23.4491 13.7047 23.4464C13.8351 23.4464 13.9622 23.4054 14.0678 23.329C14.2008 23.2324 14.2901 23.0871 14.3163 22.9249L15.3566 16.3471L18.595 16.8604C18.6755 16.8738 18.7579 16.8708 18.8371 16.8515C18.9164 16.8323 18.991 16.7973 19.0565 16.7485C19.1225 16.7009 19.1784 16.6406 19.2211 16.5713C19.2637 16.5019 19.2922 16.4248 19.3049 16.3444L19.4879 15.1867C19.5059 15.0658 19.4879 14.9423 19.436 14.8317Z"
        fill="#B0B0B3"
      />
      <path
        d="M23.8021 12.9418C23.7503 12.7859 23.6395 12.6565 23.4935 12.5813L17.5629 9.55867L19.051 6.63704C19.1252 6.49039 19.1381 6.32027 19.0869 6.1641C19.0357 6.00793 18.9246 5.87848 18.7779 5.80423L17.7321 5.27179C17.585 5.19682 17.4142 5.18308 17.257 5.23356C17.1012 5.28532 16.9718 5.39609 16.8966 5.54211L14.5948 10.0638C14.557 10.1359 14.5342 10.2149 14.5276 10.2961C14.521 10.3773 14.5309 10.4589 14.5566 10.5362C14.5818 10.6136 14.6221 10.6852 14.675 10.7471C14.7279 10.8089 14.7925 10.8597 14.8651 10.8966L22.3959 14.733C22.4806 14.7758 22.5739 14.7991 22.6689 14.8012C22.734 14.801 22.7986 14.7899 22.86 14.7685C22.938 14.7445 23.0103 14.7047 23.0723 14.6516C23.1343 14.5986 23.1848 14.5333 23.2205 14.4599L23.7666 13.4141C23.8039 13.3418 23.8263 13.2627 23.8324 13.1815C23.8385 13.1004 23.8282 13.0188 23.8021 12.9418Z"
        fill="#B0B0B3"
      />
    </svg>
  );
};

export default CharanchaLogo;
