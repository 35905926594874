const AppleLogo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.288 8.61297C19.1755 8.70026 17.1891 9.81956 17.1891 12.3084C17.1891 15.1871 19.7167 16.2056 19.7924 16.2308C19.7807 16.2929 19.3908 17.6255 18.4597 18.9834C17.6294 20.1784 16.7623 21.3714 15.4432 21.3714C14.1241 21.3714 13.7846 20.6052 12.2618 20.6052C10.7779 20.6052 10.2502 21.3966 9.04363 21.3966C7.83704 21.3966 6.99514 20.2909 6.02715 18.933C4.90591 17.3384 4 14.8612 4 12.5101C4 8.73906 6.45198 6.73907 8.86516 6.73907C10.1474 6.73907 11.2163 7.58097 12.0213 7.58097C12.7875 7.58097 13.9825 6.68863 15.4413 6.68863C15.9941 6.68863 17.9805 6.73907 19.288 8.61297ZM14.7487 5.09213C15.352 4.37632 15.7788 3.38312 15.7788 2.38991C15.7788 2.25218 15.7672 2.11251 15.742 2C14.7604 2.03686 13.5926 2.65373 12.8884 3.47041C12.3356 4.09892 11.8196 5.09213 11.8196 6.09892C11.8196 6.25023 11.8448 6.40153 11.8564 6.45003C11.9185 6.46167 12.0194 6.47525 12.1202 6.47525C13.0009 6.47525 14.1086 5.88553 14.7487 5.09213Z"
        fill="#0F1010"
      />
    </svg>
  );
};

export default AppleLogo;
