const MilelogLogo = () => {
  return (
    <svg width="217" height="62" viewBox="0 0 217 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M216.87 41.9356C216.87 44.0135 215.267 45.6164 213.308 45.6164C211.23 45.6164 209.628 44.0135 209.628 41.9356C209.628 39.8578 211.23 38.2549 213.308 38.2549C215.267 38.2549 216.87 39.8578 216.87 41.9356Z"
        fill="#6947F2"
      />
      <path
        d="M170.645 28.8741C170.645 18.7224 177.353 12.1921 186.08 12.1921C192.017 12.1921 196.291 15.1604 198.191 18.663V12.7264H203.653V45.9718C203.653 54.9361 197.657 61.2884 188.099 61.2884C179.55 61.2884 173.435 56.9546 172.366 50.0087H177.709C178.897 53.9269 182.756 56.5984 188.099 56.5984C193.857 56.5984 198.191 52.9177 198.191 45.9718V39.1446C196.232 42.6472 192.017 45.7937 186.08 45.7937C177.353 45.7937 170.645 38.9665 170.645 28.8741ZM198.191 28.9335C198.191 21.3939 193.085 16.882 187.149 16.882C181.212 16.882 176.166 21.2158 176.166 28.8741C176.166 36.5324 181.212 41.0443 187.149 41.0443C193.085 41.0443 198.191 36.5918 198.191 28.9335Z"
        fill="#100E12"
      />
      <path
        d="M166.743 28.9335C166.743 39.2633 159.322 45.7937 150.061 45.7937C140.859 45.7937 133.854 39.2633 133.854 28.9335C133.854 18.663 141.096 12.1921 150.298 12.1921C159.559 12.1921 166.743 18.663 166.743 28.9335ZM139.375 28.9335C139.375 37.1855 144.421 41.0443 150.061 41.0443C155.701 41.0443 161.222 37.1855 161.222 28.9335C161.222 20.7409 155.819 16.882 150.239 16.882C144.54 16.882 139.375 20.7409 139.375 28.9335Z"
        fill="#100E12"
      />
      <path d="M122.444 45.2589V1.32748H127.846V45.2589H122.444Z" fill="#100E12" />
      <path
        d="M100.657 16.7633C95.3735 16.7633 90.9803 20.2659 90.3867 26.6182H110.928C110.987 20.2659 106.238 16.7633 100.657 16.7633ZM115.855 35.2264C114.193 41.163 108.968 45.7937 100.895 45.7937C91.6334 45.7937 84.8062 39.2633 84.8062 28.9335C84.8062 18.663 91.3959 12.1921 100.895 12.1921C110.334 12.1921 116.508 18.8411 116.508 27.7462C116.508 28.9335 116.449 29.8834 116.33 31.0113H90.3273C90.7429 37.601 95.3735 41.2224 100.895 41.2224C105.763 41.2224 108.85 38.729 110.037 35.2264H115.855Z"
        fill="#100E12"
      />
      <path d="M73.3967 45.2589V1.32748H78.799V45.2589H73.3967Z" fill="#100E12" />
      <path
        d="M59.9789 45.2591V12.7261H65.3812V45.2591H59.9789ZM62.7691 7.44244C60.6913 7.44244 59.0883 5.83954 59.0883 3.7617C59.0883 1.68387 60.6913 0.0809631 62.7691 0.0809631C64.7282 0.0809631 66.3311 1.68387 66.3311 3.7617C66.3311 5.83954 64.7282 7.44244 62.7691 7.44244Z"
        fill="#100E12"
      />
      <path
        d="M46.9591 45.2579V26.8542C46.9591 20.2051 43.3971 16.8212 38.0541 16.8212C32.5924 16.8212 28.8523 20.3239 28.8523 27.3292V45.2579H23.5092V26.8542C23.5092 20.2051 19.9472 16.8212 14.6042 16.8212C9.14248 16.8212 5.40238 20.3239 5.40238 27.3292V45.2579H0V12.7249H5.40238V17.4149C7.53958 13.9716 11.3984 12.1313 15.6728 12.1313C21.0158 12.1313 25.4683 14.5059 27.6056 19.374C29.5053 14.684 34.1359 12.1313 39.1227 12.1313C46.5436 12.1313 52.3021 16.7619 52.3021 26.0825V45.2579H46.9591Z"
        fill="#100E12"
      />
    </svg>
  );
};

export default MilelogLogo;
